<template>
    <div class="constructionProject_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.qualificationAdvantageName"> </CourseTitle>
            <div class="content engineeringQualification">
                <img :src="formData.qualificationAdvantageImgUrl" alt="" />
                <div class="btn display pointer" @click="service">了解更多 >></div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.eightServiceAreasName"> </CourseTitle>
            <div class="content eightMajorServices">
                <img :src="formData.eightServiceAreasImgUrl" alt="" />
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.oneClickDeclarationName"> </CourseTitle>
            <div class="content constructionProject">
                <div class="list flex" v-for="(
						item, index
					) in formData.constructionProjectOneClickDeclarations" :key="index"
                    :class="index % 2 == 0 ? 'list_fl' : 'list_fr'">
                    <div class="fl">
                        <div class="name">{{ item.oneClickDeclarationName }}</div>
                    </div>
                    <div class="fr">
                        <p>
                            {{ item.oneClickDeclarationIntroduce }}
                        </p>
                    </div>
                </div>
                <div class="btn display pointer" @click="service">了解更多资质</div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.successfulCaseName"> </CourseTitle>
            <div class="content successfulCase flex flex-wrap">
                <div class="successfulCase_list" v-for="(item, index) in formData.constructionProjectSuccessfulCases"
                    :key="index">
                    <img :src="item.successfulCaseImgUrl" alt="" />
                    <div class="successfulCase_list_name display">
                        {{ item.successfulCaseName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.ourAdvantageName" :isWhite="1">
            </CourseTitle>
            <div class="content ourAdvantages">
                <ul>
                    <li v-for="(item, index) in formData.constructionProjectOurAdvantages" :key="index"
                        :class="index % 2 == 0 ? 'fl_li' : ''">
                        <div class="name">{{ item.ourAdvantageName }}</div>
                        <div class="text">{{ item.ourAdvantageIntroduce }}</div>
                    </li>
                </ul>
                <div class="display">
                    <img class="bgImg" :src="formData.ourAdvantageImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.intimateServiceName"> </CourseTitle>
            <div class="content notificationProcedures">
                <ul class="notificationProcedures_ul display">
                    <li class="notificationProcedures_li display column" v-for="(
							item, index
						) in formData.constructionProjectIntimateServices" :key="index">
                        <img :src="item.intimateServiceImgUrl" alt="" />
                        <img class="icon_jt" src="./img/constructionProject/jt.png" alt="" />
                        <div class="name">{{ item.intimateServiceName }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.freeConsultationName"> </CourseTitle>
            <FreeCharge :status="10" :type="'建筑工程资质申报'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import ProblemSolving from '@/components/problemSolving.vue'
import FreeCharge from '@/components/freeCharge'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ProblemSolving,
        FreeCharge,
    },
    data() {
        return {
            list: [{}, {}, {}, {}, {}],
            ourAdvantagesList: [{}, {}, {}, {}, {}, {}],
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.constructionProjectApi().then((res) => {
                this.formData = res.data
            })
        },

        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.constructionProject_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
    }

    .module1 {
        height: 650px;
        background: url('./img/constructionProject/bg1.png') no-repeat;
        background-size: 100% 100%;

        .engineeringQualification {
            margin-top: 33px;
        }

        img {
            width: 1200px;
            height: 280px;
        }

        .btn {
            margin-top: 69px;
        }
    }

    .module2 {
        height: 800px;

        .eightMajorServices {
            height: 600px;
            margin-top: 34px;
        }
    }

    .module3 {
        height: 1170px;
        background: #fafafa;

        .btn {
            margin-top: 60px;
        }

        .constructionProject {
            margin-top: 31px;

            .list_fl {
                .fl {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .fr {
                    position: absolute;
                    top: 22px;
                    left: 152px;
                }
            }

            .list_fr {
                .fl {
                    position: absolute;
                    top: 0;
                    right: 0;

                    .name {
                        margin-left: 72px;
                    }
                }

                .fr {
                    position: absolute;
                    top: 22px;
                    left: 0px;
                }
            }

            .list {
                height: 132px;
                position: relative;
                margin-bottom: 35px;

                .fl {
                    width: 240px;
                    height: 132px;
                    background: linear-gradient(-23deg, #003c7e, #1585ff);
                    box-shadow: 0px 1px 10px 0px rgba(0, 51, 107, 0.25);
                    padding-top: 36px;
                    padding-left: 33px;

                    .name {
                        width: 96px;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 30px;
                        text-align: center;
                    }
                }

                .fr {
                    width: 1041px;
                    height: 125px;
                    background: #fafafa;
                    padding-left: 49px;
                    box-shadow: 0px 1px 10px 0px rgba(0, 51, 107, 0.25);
                    box-sizing: border-box;

                    p {
                        width: 960px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 30px;
                        padding-top: 25px;
                    }
                }
            }
        }
    }

    .module4 {
        height: 976px;
        background: white;

        .successfulCase {
            margin-top: 31px;

            .successfulCase_list:nth-child(3n + 1) {
                margin-left: 0 !important;
            }

            .successfulCase_list {
                width: 380px;
                height: 340px;
                border: 1px solid #dddddd;
                box-sizing: border-box;
                margin-left: 28px;
                margin-bottom: 30px;

                img {
                    width: 378px;
                    height: 276px;
                }

                .successfulCase_list_name {
                    width: 100%;
                    height: 58px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }

    .module5 {
        height: 750px;
        background: url('./img/constructionProject/bg2.png') no-repeat;
        background-size: 100% 100%;

        .ourAdvantages {
            .bgImg {
                width: 484px;
                height: 383px;
                margin-top: 92px;
            }

            ul {
                position: relative;

                .fl_li {

                    .name,
                    .text {
                        text-align: right;
                    }
                }

                li:nth-child(1) {
                    top: 60px;
                    left: 174px;
                }

                li:nth-child(2) {
                    top: 60px;
                    right: 174px;
                }

                li:nth-child(3) {
                    top: 230px;
                    left: 118px;
                }

                li:nth-child(4) {
                    top: 230px;
                    right: 118px;
                }

                li:nth-child(5) {
                    top: 410px;
                    left: 178px;
                }

                li:nth-child(6) {
                    top: 410px;
                    right: 178px;
                }

                li {
                    position: absolute;

                    .name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #fafafa;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }

                    .text {
                        width: 208px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #fafafa;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .module6 {
        height: 450px;

        .notificationProcedures {
            margin-top: 69px;

            .notificationProcedures_ul {
                .notificationProcedures_li:nth-child(1) {
                    margin-left: 0 !important;
                }

                .notificationProcedures_li:last-child {
                    .icon_jt {
                        display: none;
                    }
                }

                .notificationProcedures_li {
                    margin-left: 108px;
                    position: relative;

                    .icon_jt {
                        width: 32px;
                        height: 18px;
                        position: absolute;
                        top: 41px;
                        right: -68px;
                    }

                    img {
                        width: 96px;
                        height: 96px;
                    }

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 25px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module7 {
        background: #fafafa;
    }
}</style>